import React, { useState,useEffect} from 'react';
import LiveDate from './LiveDate';
import { Link } from 'react-router-dom';
import { DConnect, getCompany, getFeatures, getUser, Logout } from './Config';
import MenuList from './MenuList';
import moment from 'moment';

const amountformat = new Intl.NumberFormat("en-GB", { maximumFractionDigits: 2, minimumFractionDigits: 2 })

function TopMenu() {
    const [menulist, setmenulist] = useState(new MenuList())
    const [roleName, setroleName] = useState("")
    const [branchDetails, setbranchDetails] = useState({})

    useEffect(() => {
        getRole()
        getBranch()
    }, [])
    

    const getRole=()=>{
        DConnect().get(`users/fetchroleid/${getUser().role}`).then(res => {
            let respond = res.data
            setroleName(respond.rolename)
        })
    }

    const getBranch=()=>{
        DConnect().get(`branch/getBranchById/${getUser().branch}`).then(res => {
            let respond = res.data
            setbranchDetails(respond)
        })
    }

    const setMode = () => {

        if (sessionStorage.getItem('screen') == "mobile") {
            sessionStorage.setItem('screen', "desktop");
        } else if (sessionStorage.getItem('screen') == null) {
            sessionStorage.setItem('screen', "mobile");
        } else if (sessionStorage.getItem('screen') == "desktop") {
            sessionStorage.setItem('screen', "mobile");
        }
        window.location.replace('/#/dashboard');
        window.location.reload(true);
    }
    let feat = JSON.parse(getFeatures())
    return (
        <div>

            <div class="header">
                <div class="header-content">
                    <nav class="navbar navbar-expand">
                        <div class="collapse navbar-collapse justify-content-between">
                            <div class="header-left">
                                <div class="dashboard_bar">
                                {getCompany().name}  
                                {/* <p style={{fontSize:"10px",fontWeight:"lighter"}}>Test</p>       */}
                                </div>
                            </div>

                            <ul class="navbar-nav header-right">

                                {(sessionStorage.getItem('screen') == "mobile") &&
                                    <li class="nav-item dropdown notification_dropdown">
                                        <a class="nav-link  ai-icon" href="javascript:;" role="button" data-bs-toggle="dropdown">
                                            <span className='fa fa-bars'></span>
                                            {/* <span class="badge light text-white bg-primary">12</span> */}
                                        </a>
                                        <div class="dropdown-menu dropdown-menu-end">
                                            <div id="DZ_W_Notification1" class="widget-media dz-scroll p-3 height380">
                                                <p>Menu</p>
                                                <ul class="timelinef">
                                                    {menulist.map(mn => (
                                                        feat[mn.code] !== undefined &&
                                                        <li id={(mn.submenu != '') ? "sub" + mn.code : "mun" + mn.code} class={(mn.submenu != '') ? "submenu" + "sub" + mn.code : (mn.code == 1) ? "mm-active" : "" } >
                                                            {(mn.submenu == '') ?
                                                                <Link to={mn.link} aria-expanded="false" style={{fontWight:"bold"}}>
                                                                    <span class="nav-text">{mn.name}</span>
                                                                </Link> :
                                                                <a class="has-arrow ai-icon " href="javascript:void()" aria-expanded="false">
                                                                   
                                                                    <h6 class="nav-text">{mn.name}</h6>
                                                                </a>
                                                            }
                                                            {mn.submenu != '' &&
                                                                <ul aria-expanded="false">
                                                                    {mn.submenu.map(smenu => ( //feat[mn.code].includes(smenu.code) &&
                                                                        <li><Link to={smenu.link}>{smenu.name}</Link></li>
                                                                    )
                                                                    )}
                                                                </ul>
                                                            }
                                                        </li>

                                                    ))}
                                                </ul>
                                            </div>
                                            {/* <a class="all-notification" href="javascript:;">See all notifications <i class="ti-arrow-right"></i></a> */}
                                        </div>
                                    </li>
                                }

                                <li class="nav-item dropdown notification_dropdown">
                                    <a class="nav-link bell" onClick={() => setMode()}>
                                        {
                                            (sessionStorage.getItem('screen') == "mobile") ? <i id="icon-darks" class="fas fa-desktop"></i> : <i id="icon-darks" class="fas fa-mobile"></i>
                                        }

                                    </a>
                                </li>
                                <li class="nav-item dropdown notification_dropdown">
                                    <a class="nav-link bell dz-theme-mode" href="javascript:void(0);">
                                        <i id="icon-light" class="fas fa-sun"></i>
                                        <i id="icon-dark" class="fas fa-moon"></i>

                                    </a>
                                </li>

                                <li class="nav-item dropdown header-profile">
                                    <a class="nav-link" href="javascript:;" role="button" data-bs-toggle="dropdown">
                                        <i className='fa fa-user fa-2x'></i>
                                        {/* <div class="header-info">
										<span>Hello,<strong> Roberto</strong></span>
									</div> */}
                                    </a>
                                    <div class="dropdown-menu dropdown-menu-end">
                                        <a href="app-profile.html" class="dropdown-item ai-icon">
                                            <svg id="icon-user1" xmlns="http://www.w3.org/2000/svg" class="text-primary" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                                            <span class="ms-2">Profile </span>
                                        </a>
                                        <a href="email-inbox.html" class="dropdown-item ai-icon">
                                            <svg id="icon-inbox" xmlns="http://www.w3.org/2000/svg" class="text-success" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
                                            <span class="ms-2">Inbox </span>
                                        </a>
                                        <a onClick={() => Logout()} class="dropdown-item ai-icon">
                                            <svg id="icon-logout" xmlns="http://www.w3.org/2000/svg" class="text-danger" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg>
                                            <span class="ms-2" >Logout </span>
                                        </a>
                                    </div>
                                </li>


                            </ul>


                        </div>
                    </nav>
                </div>
                {/* <div className='col-md-12  alert-dark solid alert-square bg-dark' style={{color:"white",borderBottom:"6px solid #ed3b2e"}}>
                    <div className='row'>
                    <div className='col-md-6'>&nbsp; <i>{getUser().name}({roleName})</i></div>
                    <div className='col-md-6 '><i className='float-end'>System Date:&nbsp;{moment(branchDetails.system_date).format('DD MMM, YYYY')} &nbsp;&nbsp;</i></div>
                    </div>
              
                    </div> */}
            </div>
            
        </div>
    );
}

export default TopMenu;
