import React from "react";
import App from "../App";

const delay = duration => new Promise(resolve => setTimeout(resolve, duration));

 const Dashboard = React.lazy(() => import('../views/Dashboard/Dashboard'));
// const Transactions = React.lazy(() => import('../views/transactions/Transactions'));

const Roles= React.lazy(() => import('../views/Profile/Role'));
const Users = React.lazy(() => import('../views/Profile/User'));
const CompanyCon = React.lazy(() => import('../views/Company/CompanyCon'));
const CustomerCon = React.lazy(() => import('../views/Customers/CustomerCon'));
const BookingCon = React.lazy(() => import('../views/Booking/BookingCon'));
const TransactionCon = React.lazy(() => import('../views/Transactions/TransactionCon'));
// const Categories = React.lazy(() => import('../views/Category/CategoriesCon'));
 const CashierTillCon = React.lazy(() => import('../views/Reports/CashierTillCon'));
 const Product = React.lazy(() => import('../views/Products/ProductCon'));
const Branch = React.lazy(() => import('../views/Branches/BranchCon'));
const Areas = React.lazy(() => import('../views/Areas/AreasCon'));
const Region = React.lazy(() => import('../views/Region/RegionCon'));
 const CashManagementCon = React.lazy(() => import('../views/CashManagement/CashManagementCon'));
 const Transfer = React.lazy(() => import('../views/CashManagement/Transfer'));
 const EndofBusiness = React.lazy(() => import('../views/Operations/EndofBusiness'));
 const AgentReassign = React.lazy(() => import('../views/Operations/AgentReassign'));

const AgentCon = React.lazy(() => import('../views/Agents/AgentCon'));

const CompanyCons = React.lazy(() => import('../views/Settings/CompanyCon'));

const Misc = React.lazy(() => import('../views/Profile/Misc'));
const BranchReportCon = React.lazy(() => import('../views/Reports/BranchReportCon'));
const CustomerReportCon = React.lazy(() => import('../views/Reports/CustomerReportCon'));
const CustomerApprovalCon = React.lazy(() => import('../views/Approval/CustomerApprovalCon'));

const routes = [
     { path: "/dashboard", name: "Dashboard", component: Dashboard },
     { path: "/companies", name: "Companies", component: CompanyCon },
     { path: "/customer", name: "Customers", component: CustomerCon },
     
      { path: "/booking", name: "Booking", component: BookingCon },
      { path: "/transactions", name: "Transactions", component: TransactionCon },
      { path: "/deposit", name: "Deposit", component: CashManagementCon },
      { path: "/withdrawal", name: "Withdrawal", component: CashManagementCon },
      { path: "/transfer", name: "Transfer", component: Transfer },

      { path: "/branchreport", name: "Branch Report", component: BranchReportCon },
      { path: "/customerlisting", name: "Customer Report", component: CustomerReportCon },


      { path: "/customerapproval", name: "Customer Approval", component: CustomerApprovalCon },
      { path: "/accountapproval", name: "Account Approval", component: CustomerApprovalCon },
      { path: "/transactionalapproval", name: "Customer Approval", component: CustomerApprovalCon },

      
      { path: "/tillreport", name: "Transfer", component: CashierTillCon },
      { path: "/agents", name: "Agent", component: AgentCon },
      { path: "/products", name: "Products", component: Product },
      { path: "/branches", name: "Branches", component: Branch },
      { path: "/areas", name: "Areas", component: Areas },
      { path: "/region", name: "Region", component: Region },
      { path: "/endofbusiness", name: "End of Business", component: EndofBusiness },
      { path: "/reassignagent", name: "Agent Reassigned", component: AgentReassign },

      //reassignagent
    
     { path: "/role", name: "Role", component: Roles },
     { path: "/users", name: "Users", component: Users },
    { path: "/company", name: "Company Settings", component: CompanyCons }


]

export default routes;