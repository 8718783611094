import React, { useEffect, useState } from 'react';
import bread from './bread.jpg'
import { DoLogin, DConnect } from './components/Config'
function Login() {
    const [formitems, setformitems] = useState({ username: "", password: "" })

    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setformitems(prevState => ({ ...prevState, [name]: value }));
    }
    const Dologin = (e) => {
        e.preventDefault();
        DoLogin(formitems)
    }



    return (
        <div className='vh-100'>

            <div class="authincation h-100">
                <div class="container h-100">
                    <div class="row justify-content-center h-100 align-items-center">
                        <div class="col-md-6">
                            <div class="authincation-content">
                                <div class="row no-gutters">
                                    <div class="col-xl-12">
                                        <div class="auth-form">
                                            <div class="text-center mb-3">
                                                <a href=""><img src="images/logo-full.png" alt="" /></a>
                                            </div>
                                            <h4 class="text-center mb-4">Sign in your account</h4>
                                            <form onSubmit={Dologin}>
                                                <div class="form-group">
                                                    <label class="form-label">Email/Username</label>
                                                    <input type="text" class="form-control" name="username" id="username" value={formitems.username} onChange={handleChange} />
                                                </div>
                                                <label class="form-label">Password</label>
                                                <div class="mb-3 position-relative">
                                                    <input type="password" id="dz-password" class="form-control" name="password" value={formitems.password} onChange={handleChange} />
                                                    <span class="show-pass eye">
                                                        <i class="fa fa-eye-slash"></i>
                                                        <i class="fa fa-eye"></i>
                                                    </span>
                                                </div>
                                                <div class="form-row d-flex justify-content-between flex-wrap mt-4 mb-2">
                                                    <div class="form-group">
                                                        <div class="form-check custom-checkbox ms-1">
                                                            <input type="checkbox" class="form-check-input" id="basic_checkbox_1" />
                                                            <label class="form-check-label" for="basic_checkbox_1">Remember my preference</label>
                                                        </div>
                                                    </div>
                                                    {/* <div class="form-group">
                                                <a href="page-forgot-password.html">Forgot Password?</a>
                                            </div> */}
                                                </div>
                                                <div class="text-center">
                                                    <button type="submit" class="btn btn-primary btn-block">Sign Me In</button>
                                                </div>
                                            </form>
                                            <div class="new-account mt-3">
                                                <p class="mb-0"> Powered by Tarixel Technology</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login