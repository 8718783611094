import React, { Suspense, Fragment, useState, useEffect } from 'react';
import { HashRouter, Link, Redirect, Route, Switch } from 'react-router-dom';
import TopMenu from './TopMenu';
import SideMenu from './SideMenu';
import routes from './routes';
import logo from './logo2.png'

function DefaultLayout() {

    const loader = () => <div id="loader"></div>;
    return (
        // 274151
        <Suspense fallback={loader}>
            <HashRouter>
                <div id="main-wrapper">
                    <div class="nav-header">
                        <a href="" class="brand-logo">
                            <img src={logo} width={"70%"} />
                        </a>
                       
                        <div class="nav-control">
                        {(sessionStorage.getItem('screen') != "mobile")&&
                            <div class="hamburger">
                                <span class="line"></span><span class="line"></span><span class="line"></span>
                            </div>
                              }
                        </div>
                          
                    </div>


                    <TopMenu />
                    
                    {(sessionStorage.getItem('screen') == "mobile")?
                       "":<SideMenu />
                    }

                    <Switch>
                        <div class={(sessionStorage.getItem('screen')=="desktop" || sessionStorage.getItem('screen')==null)?"content-body":"content-bodys"}>
                            <div class="container-fluid">
                                
                                {routes.map((route, idx) => {
                                    return route.component ? (
                                        <Route key={idx} path={route.path} exact={route.exact} name={route.name} render={props => (
                                            <route.component {...props} />
                                        )} />
                                    ) : (null);
                                })}
                                {/* <Redirect from="/" to="/dashboard" /> */}
                            </div>
                        </div>
                    </Switch>
                </div>

            </HashRouter>
        </Suspense>
    );
}

export default DefaultLayout;
