import React, { useEffect } from 'react';
import Axios from 'axios';
import CryptoJS from 'crypto-js';
import base64 from "base-64";
import $ from 'jquery';
import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';
import { ModalContent, ModalFooter, ModalButton, useDialog, Confirm, Alert } from 'react-st-modal';
import DataTable from 'datatables.net';
import { NotificationManager } from 'react-notifications';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const ShowAlert = async (props) => await Alert(props.message, props.title, props.btnText);
const ShowConfirm = async (props) => await Confirm(props.message, props.title);

const InnerModal = ({ children, ...props }) => {
    const dialog = useDialog();
    return <div>
        <ModalContent> {children} </ModalContent>
        <ModalFooter>
            {props.btns.map((b, idx) =>
                <ModalButton className={`btn-${b.color}`} style={{ padding: '5px 15px' }} onClick={() => { dialog.close(idx); }} >{b.label}</ModalButton>
            )}
        </ModalFooter>
    </div>
};

const notification = (message, type) => {
    if (type == "success") {
        toast.success(message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        });
    } else if (type == "error") {
        toast.error(message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        });
    } else if (type == "warning") {
        toast.warning(message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        });
    }

}


const DoLogin = (user) => {
    Axios(PostOptions('POST', user, `users/login`)).then(res => {
        let respond = res.data
        if (respond) {
            getRoles(user)
            getCompanys()
        } else {
            notification("Invalid Credentials", 'error');
        }
    })
}

const getCompanys = () => {
    DConnect().get(`company/getcompanylist`).then(res => {
        let respond = res.data
        setCompany(respond)
    })
}

const getRoles = (respond) => {
    DConnect().get(`users/getusernameemail/${respond.username}`).then(res => {
        let user = res.data
        DConnect().get(`users/fetchroleid/${user.role}`).then(res => {

            let features = res.data
            setFeatures(features.features)
            setUser(user)
            setUsername(user.username)
            window.location.replace('/#/dashboard');
            window.location.reload(true);
            // window.location.replace('/#/dashboard');
            // window.location.reload(true);
        })
    })
}

const DConnect = () => {
    return Axios.create({
        baseURL: BaseURL(),
        timeout: 60000,
        headers: {
            // 'Content-Type': 'application/json',

            // 'Access-Control-Allow-Origin': '*',
            // 'Access-Control-Allow-Methods': '*'
        }
    });
}
const PostOptions = (meth, formBody, area) => {
    return {
        method: meth,
        url: BaseURL() + area,
        data: formBody,
        headers: {
            // 'Content-Type': 'application/json',

            // 'Access-Control-Allow-Origin': '*',
            // 'Access-Control-Allow-Methods': '*'
        }
    }
}
const PostOptionsImage = (meth, formBody, area) => {
    return {
        method: meth,
        url: BaseURL() + area,
        data: formBody,
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': ` Bearer ${getToken()}`,
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': '*'
        }
    }
}

const Table = ({ children, ...props }) => {

    // (function ($) {
    //     var table = $('#example5').DataTable({
    //         searching: true,
    //         paging: true,
    //         select: false,
    //         lengthChange: false
    //     });
    //     $('#example tbody').on('click', 'tr', function () {
    //         var data = table.row(this).data();
    //     });
    // })($);

    useEffect(() => {
        if (props.data.length > 0) {
            let table;
            if ($.fn.dataTable.isDataTable(`#${props.id}`)) {
                table = $(`#${props.id}`).DataTable();
                
                
            } else {    
                table = $(`#${props.id}`).DataTable({
                    'lengthChange': true,
                    'colReorder': true,
                    select: true,
                    searching: props.search ? true : false,
                    columnDefs: [
                        {
                            orderable: false,
                            //render: DataTable.render.select(),
                            targets: 0
                        }
                    ],
                    select: {
                        style: 'multi', // Allows multi-row selection
                        selector: 'td:first-child input[type="checkbox"]' // Checkbox selector
                    },
                    'dom': 'lfBrtip',
                    'buttons': ['copy', 'excel', 'csv', 'pdf', 'print', 'colvis'],
                    "rowCallback": function (row, data) {
                        if ($(row).hasClass('selected')) {
                            $(row).addClass('selected');
                        }
                    }
                });

            }

           // console.log('Buttons:', table.buttons().container());
            $('div.dataTables_length select').removeClass('custom-select').removeClass('custom-select-sm');
          //   table.buttons().container().appendTo( `#${props.id}_wrapper .col-md-6:eq(0)` );
        }
    }, [props]);

    return (
        <div className='table-responsive'>
            <table id={props.id} className={` table ${props.striped ? 'table-striped' : ''} ${props.hover ? 'table-hover' : ''} ${props.borderless ? 'table-borderless' : ''} ${props.condensed ? 'table-condensed' : ''}  dataTablesCard fs-14  patient-list`} cellSpacing="0" cellPadding="0">
                <thead ><tr>{children}</tr></thead>
                <tbody>
                    {props.data.map(dt => <tr>
                        {children.map(ch => {
                            let align = (ch.props.dataAlign !== undefined) ? ch.props.dataAlign : 'left';
                            return (<td className={`text-${align}`} >{
                                (ch.props.dataFormat !== undefined) ?
                                    ch.props.dataFormat(dt[ch.props.dataField], dt)
                                    : dt[ch.props.dataField]
                            }</td>)
                        })}
                    </tr>)}
                </tbody>
                
                {props.footer !== undefined && props.footerData !== undefined &&
                    <tfoot>
                        
                        <tr>
                            {children.map((ch, idx) => {
                                let itm = props.footerData.filter(fdata => fdata.columnIndex === idx);
                                let align = (itm[0] !== undefined && itm[0].align !== undefined) ? itm[0].align : 'left';
                                 let itmValue = (itm[0] !== undefined) ?
                                    (itm[0].formatter !== undefined) ? itm[0].formatter(props.data) : itm[0].label : '';
                                return <th className={`text-${align}`}>{itmValue}</th>;
                            })}
                        </tr>
                    </tfoot>}
            </table>
        </div>);
};

const Column = ({ children, ...props }) => {
    let align = (props.dataAlign !== undefined) ? props.dataAlign : 'left';
    let width = (props.width !== undefined) ? props.width : '';
    let background = props.background// (props.background !="") ? props.background : 'dafault';
    return (<th width={width} className={`text-${align} ` + "bg-primarys "} {...props}>{children}</th>)
};

const account = (length, type = "mix") => {
    let sValidCharacters = (!type) ? "0123456789" : "AC0123456789";
    var sCharCode = "";
    for (var i = 0; i <= length; i++) {
        sCharCode = sCharCode + sValidCharacters.charAt(parseInt(Math.random() * sValidCharacters.length))
        if ((i === 2) || (i === 6)) {
            sCharCode = sCharCode
        }
    }
    return sCharCode
}

const codes = () => {
    let sValidCharacters = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var sCharCode = "";
    for (var i = 0; i <= 10; i++) {
        sCharCode = sCharCode + sValidCharacters.charAt(parseInt(Math.random() * sValidCharacters.length))
        if ((i === 2) || (i === 6)) {
            sCharCode = sCharCode + "-"
        }
    }
    return sCharCode
}

Table.Col = Column;

//headers: { 'Content-Type': 'multipart/form-data' },


const Downloadlink = () => {
    //return 'http://144.202.59.233:8081/msms/'
    //return 'http://logiciel-test-srv.com:7000/applicationapi/public/attachments/'
}

const BaseURL = () => {
    return 'https://staging.tarixel.com/tari_agent/';
    //return 'http://localhost/agentapi/agency_app/';
}

const Logout = () => {
    sessionStorage.removeItem('bank_token');
    sessionStorage.removeItem('bank_user');
    sessionStorage.removeItem('bank_features');
    sessionStorage.removeItem('bank_tokens');
    sessionStorage.removeItem('bank_client');
    sessionStorage.removeItem('screen');
    sessionStorage.removeItem('bank_company');
    window.location.replace('/login');
    window.location.reload(true);
}

const predefinedBottomRanges = [
    {
      label: 'Today',
      value: [new Date(), new Date()],
       placement: 'left'
    },
    {
      label: 'Yesterday',
      value: [addDays(new Date(), -1), addDays(new Date(), -1)],
       placement: 'left'
    },
    {
      label: 'This week',
      value: [startOfWeek(new Date()), endOfWeek(new Date())],
       placement: 'left'
    },
    {
      label: 'Last 30 days',
      value: [subDays(new Date(), 29), new Date()],
       placement: 'left'
    },
    {
      label: 'This month',
      value: [startOfMonth(new Date()), new Date()],
       placement: 'left'
    },
    {
      label: 'Last month',
      value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
       placement: 'left'
    },
    {
      label: 'This year',
      value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
       placement: 'left'
    },
    {
      label: 'Last year',
      value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().getFullYear(), 0, 0)],
       placement: 'left'
    }
  ];
const setUser = (user) => sessionStorage.setItem('bank_user', JSON.stringify(user));
const getUser = () => JSON.parse(sessionStorage.getItem('bank_user'));

const setCompany = (user) => sessionStorage.setItem('bank_company', JSON.stringify(user));
const getCompany = () => JSON.parse(sessionStorage.getItem('bank_company'));


const getAuthorization = () => (getUser() == null) ?
    { 'username': '', 'token': '' } :
    { 'username': getUser().username, 'token': getUser().token };

const setFeatures = (userole) => sessionStorage.setItem('features', JSON.stringify(userole));
const getFeatures = () => JSON.parse(sessionStorage.getItem('features'));

const setUsername = (userole) => sessionStorage.setItem('bank_username', JSON.stringify(userole));
const getUsername = () => JSON.parse(sessionStorage.getItem('bank_username'));

const LoggedIn = () => getUser() !== null;
const IsAuthenticated = () => LoggedIn();
const setToken = (token) => sessionStorage.setItem('bank_token', token);
const getToken = () => sessionStorage.getItem('bank_token');

const Config = () => {

}

Config.DoLogin = DoLogin
Config.BaseURL = BaseURL
Config.PostOptions = PostOptions
Config.setToken = setToken
Config.getToken = getToken
Config.PostOptionsImage = PostOptionsImage
Config.getAuthorization = getAuthorization
Config.IsAuthenticated = IsAuthenticated
Config.setFeatures = setFeatures
Config.getFeatures = getFeatures
Config.Logout = Logout
Config.setUser = setUser
Config.getUser = getUser
Config.DConnect = DConnect
Config.getUsername = getUsername
Config.Table = Table;
Config.codes = codes
Config.account = account
Config.notification = notification
Config.InnerModal = InnerModal
Config.getCompany=getCompany
Config.setCompany=setCompany
Config.predefinedBottomRanges=predefinedBottomRanges
export default Config;
export {
    predefinedBottomRanges,getCompany,setCompany,notification, InnerModal, codes, account, Table, BaseURL, PostOptionsImage, PostOptions, setToken, getUsername, getToken, getAuthorization, IsAuthenticated, setFeatures, getFeatures, Logout, getUser, setUser, DConnect, DoLogin
}