import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

  import 'react-toastify/dist/ReactToastify.css';

ReactDOM.render(
  <Suspense fallback={(<div id="loader"></div>)}>
    <React.StrictMode>
      {/* <BrowserRouter> */}
      <ToastContainer />
        <App />
       
      {/* </BrowserRouter> */}
    </React.StrictMode></Suspense>,
  document.getElementById('taripack')
);

reportWebVitals();
