import React, { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';

export default function MenuList() {
    const [isSuper, setisSuper] = useState(false)

    useEffect(() => {
        //    if(getUser() ==null){
        //     setisSuper(true)

        //    }
    }, [])

    var menu = [
        {
            code: '1', role: "Dashboard", src: "dashboard", icon: "flaticon-381-networking", name: "Dashboard", link: "dashboard",
            submenu: []
        },
        {
            code: '2', role: "Customer", src: "customer", name: 'Customers', icon: "flaticon-381-user-2", link: "customer",
            submenu: []
        },
        // {
        //     code: '3', role: "Agents", src: "agents", name: 'Agents', icon: "flaticon-381-smartphone", link: "agents",
        //     submenu: []
        // },
        {
            code: '4', role: "Cash Management", src: "bank", name: 'Cash Management', icon: "flaticon-381-upload", link: "report",
            submenu: [
                { code: '41', role: "Deposit", src: "deposit", link: "deposit", name: "Deposit" },
                { code: '42', role: "Withdrawal", src: "withdrawal", link: "withdrawal", name: "Withdrawal" },
                { code: '43', role: "Agent Transfers", src: "transfer", link: "transfer", name: "Agent Transfers" },
                //{code:'64',role:"Customer Transfer",src:"customertransfer",link:"customertransfer", name:"Customer Transfer"},
            ]
        },

       
        {
            code: '6', role: " Approval", src: "Approval", name: ' Approval', icon: "flaticon-381-clock", link: "approval",
            submenu: [
                {code:'61',role:"Cusromer Approval",src:"Customer Approval",link:"customerapproval", name:"Customer Approval"},
               
                {code:'63',role:"Transactions Approval",src:"Transactions Approval",link:"transactionalapproval", name:"Transactions Approval"},
            ]
        },

        // {
        //     code: '7', role: "Transactions", src: "transactions", name: 'Transactions', icon: "flaticon-381-list", link: "transactions",
        //     submenu: []
        // },
       
        {
            code: '8', role: "Report", src: "bank", name: 'Report', icon: "flaticon-381-file", link: "report",
            submenu: [
                { code: '81', role: "Transaction", src: "Sales", link: "transactions", name: "Transaction" },
                { code: '82', role: "Branch Report", src: "Collection", link: "branchreport", name: "Branch Report" },
                { code: '83', role: "Cashier Till Report", src: "tillreport", link: "tillreport", name: "Cashier Till Report" },
                { code: '84', role: "Customer Listing", src: "tillreport", link: "customerlisting", name: "Customer Listing" },
                // { code: '85', role: "Account Listing", src: "tillreport", link: "accountlisting", name: "Account Listing" },
                // { code: '86', role: "Agent Report", src: "tillreport", link: "agentreport", name: "Agent Report" },
                // {code:'64',role:"Attendance Report",src:"attendance",link:"attendance", name:"Attendance Report"},
            ]
        },
        {
            code: '10', role: "Users", src: "Users", name: 'Users', icon: "flaticon-381-diploma", link: "users",
            submenu: [
                { code: '11', role: "Role", src: "Role", link: "role", name: "Role" },
                { code: '12', role: "Users", src: "Users", link: "users", name: "Users" },
                // { code: '13', role: "Misc", src: "Misc", link: "misc", name: "Misc" },
                //{code:'34',role:"Attendance",src:"Attendance",link:"attendance", name:"Attendance"}
            ]
        },{
            code: '5', role: "Operations", src: "Operation", name: 'Operation', icon: "flaticon-381-network", link: "operations",
            submenu: [
                { code: '51', role: "Close of Business", src: "Close of Business", link: "endofbusiness", name: "Close of Business" },
                {code:'52',role:"Reassign Agent",src:"production",link:"reassignagent", name:"Reassign Agent"},
                {code:'53',role:"Import Customer",src:"Import Customer",link:"importcustomer", name:"Import Customer"},
                {code:'54',role:"Import Accounts",src:"Import Accounts",link:"importaccounts", name:"Import Accounts"},
            ]
        },
        {
            code: '9', role: "Preference", src: "Preference", name: 'Preference', icon: "flaticon-381-controls", link: "Preference",
            submenu: [
                { code: '91', role: "Region", src: "Region", link: "region", name: "Region" },
                { code: '93', role: "Branches", src: "Branches", link: "branches", name: "Branches" },
                { code: '94', role: "Areas", src: "Areas", link: "areas", name: "Areas" },
                { code: '92', role: "Product", src: "Product", link: "products", name: "Product" },
               
            ]
        },
        
        {
            code: '110', role: "Settings", src: "Settings", name: 'Settings', icon: "flaticon-381-settings-2", link: "settings",
            submenu: [
                { code: '1101', role: "Company Settings", src: "Company Settings", link: "company", name: "Company Settings" },
                // { code: '12', role: "Users", src: "Users", link: "users", name: "Users" },
                // { code: '13', role: "Misc", src: "Misc", link: "misc", name: "Misc" },
                //{code:'34',role:"Attendance",src:"Attendance",link:"attendance", name:"Attendance"}
            ]
        }
    ]



    return menu;
}