import React, { Fragment, useState, useEffect } from 'react';
import $ from 'jquery'
import MenuList from './MenuList'
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { getFeatures, Logout } from './Config';

function SideMenu() {
    const [menulist, setmenulist] = useState(new MenuList())

    useEffect(() => {
       

        let tid=[]
       
        $("li").removeClass("mm-active");
        $("#mun1").addClass("mm-active");
        $('ul#menu').on('click', 'li', (ex) => {
            $('ul#menu li ul li').on('click', (e) => { });
            var id = $(ex.currentTarget).attr('id')
            $("li").removeClass("mm-active");
            $("#"+id).addClass("mm-active");     
            if ($('#'+id).hasClass('mm-active')) {
            } else {
            }
           
        });
    }, [])
    let feat=JSON.parse(getFeatures())
    return (
        <div>
            <div class="deznav">
                <div class="deznav-scroll">
                    <ul class="metismenu" id="menu">

                        {menulist.map(mn => (
                            feat[mn.code] !== undefined &&
                            <>
                                <li  id={(mn.submenu != '') ? "sub" + mn.code : "mun" + mn.code} class={ (mn.submenu != '') ? "submenu" + "sub" + mn.code +" active-no-child ": (mn.code == 1) ? "" : ""}>
                                {(mn.submenu == '') ?
                                <Link to={mn.link} aria-expanded="false">
                                 
                                        <i class={mn.icon}></i>
                                        <span class="nav-text">{mn.name}</span>
                                        </Link>:
                                    <a class="has-arrow ai-icon " href="javascript:void()" aria-expanded="false">
                                        <i class={mn.icon}></i>
                                        <span class="nav-text">{mn.name}</span>
                                    </a>
     }
                                    {mn.submenu != '' &&
                                        <ul aria-expanded="false">
                                            {mn.submenu.map(smenu => ( //feat[mn.code].includes(smenu.code) &&
                                                <li><Link to={smenu.link}>-{smenu.name}</Link></li>
                                            )
                                            )}
                                        </ul>
                                    }
                                </li>


                                
                            </>
                        ))}
                    </ul>

                    <br></br><br></br><br></br>
                    <div class="copyright">
                        <p class="fs-14 font-w200"><strong class="font-w400">Agent Cash collection Admin Dashboard</strong> © 2023 All Rights Reserved</p>
                        <p class="fs-12">Made with <span class="heart"></span> by Tarixel</p>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default SideMenu;
