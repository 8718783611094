import './App.css';
import Login from './Login';
import DefaultLayout from './components/DefaultLayout';
import { IsAuthenticated } from './components/Config';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
//IsAuthenticated() && IsAuthenticated() !==null
const App = () =>(IsAuthenticated() && IsAuthenticated() !==null) ? 
<DefaultLayout />: <Login />

export default App;